<script lang="ts">
// import { useConfigStore } from '~/store'

import type { ApiService } from '@nuxtDatakodeUtils/utils/api.service'

export default {
  props: {
    color: { type: String, required: false, default: "light" },
  },
  data() {
    return {
      api_version: '',
      api_env: 'production',
    }
  },
  mounted() {
    const api = this.$api as ApiService;
    api.setRequest({
      url: '',
      method: 'GET',
    }).then(async (data) => {
      this.api_version = 'v' + data?.pretty_version ?? '?';
      this.api_env = data?.env?.toUpperCase()
    })
  },


}
</script>

<template>
  <v-footer
    class="border-t"
    :color="color"
    style="z-index:50; position: inherit;"
  >
    <div class="w-100 align-center">
      <v-row class="ma-0">
        <v-col class="py-1">
          <a href="https://www.geoplateforme17.fr/" target="_blank">
            <v-btn variant="text">
              © {{ new Date().getFullYear() }}
              Géoplateforme17
            </v-btn>
          </a>

          <span class="api-version">{{ api_version }}
                  <v-badge v-if="'PRODUCTION'!==api_env"
                           :color="api_env==='LOCAL'?'danger':'warning'"
                           :content="api_env"
                           inline
                  ></v-badge>
                      </span>
        </v-col>

        <v-col :class="$vuetify.display.smAndUp && 'text-right'" class="py-1">
          <div>
            <NuxtLink to="/mentions-legales">
              <v-btn variant="text">
                Mentions légales
              </v-btn>
            </NuxtLink>
            <v-divider class="border-opacity-100" vertical/>
            <NuxtLink to="/donnees-personnelles">
              <v-btn variant="text">
                Données personnelles
              </v-btn>
            </NuxtLink>
          </div>
        </v-col>
      </v-row>

    </div>
  </v-footer>
</template>
